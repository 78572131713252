$title-size: 55px;
$transition-duration: 350ms;

.dropdown {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--open,
  &--close {
    transition: opacity $transition-duration linear,
    transform $transition-duration linear;
  }

  &--open {
    opacity: 1;
    transform: scale(1);
  }

  &--close {
    opacity: 0;
    transform: scale(0);
    height: 0 !important;
  }

  &__themes {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__current {
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    max-width: 250px;
    display: flex;
    position: relative;
    overflow: hidden;

    span {
      text-align: center;
      padding: 10px;
      display: flex;
      align-items: center;
    }

    span:nth-child(1) {
      background-color: var(--color-black);
      color: var(--color-white);
      border-radius: 5px;
    }

    span:nth-child(1):not(:last-child) {
      border-radius: 5px 0 0 5px;
      padding-right: 30px;
    }

    span:nth-child(2) {
      background-color: var(--color-white);
      color: var(--color-black);
      border-radius: 0 5px 5px 0;
      position: relative;

      &:before {
        content: '';
        border-width: 26px;
        border-style: solid;
        border-color: transparent var(--color-white) transparent transparent;
        position: absolute;
        left: -48px;
        width: 0;
        height: 0;
      }
    }
  }

  &__item {
    font-size: 20px;
    text-transform: capitalize;
  }

  .swiper,
  .swiper-wrapper,
  .swiper-slide {
    position: relative;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: transform ease 300ms;
    cursor: pointer;

    &.swiper-slide-active {
      transform: scale(1.5);
    }
  }
}
