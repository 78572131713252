$full_width: 65%;

.content {
  position: relative;
  height: 100%;
  background-color: var(--color-black);
  display: flex;

  &__element {
    width: calc(100% - #{$full_width});
    transition: width ease 300ms;
    position: relative;
    overflow: hidden;

    &.full {
      width:  $full_width;
    }
  }


  // pour le dictionnaire wow effect là

  &__children {
    transition: opacity 150ms 150ms ease;
    opacity: 1;
    height: 100%;
    position: relative;
    overflow-y: scroll;

    &.hide {
      opacity: 0;
    }
  }

  //&__overlay {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  background-color: var(--color-orange);
  //  z-index: 1;
  //  transform: translateX(-100%);
  //
  //  &.animate {
  //    animation: animate 1000ms ease;
  //  }
  //}
}

@keyframes animate {
  from { transform: translateX(-100%)}
  to { transform: translateX(100%)}
}
