.locales-selector {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 10px;

    &--selected {
      background-color: var(--color-orange);
      color: var(--color-white);
      border-radius: 5px;
    }

    & > img {
      display: block;
      max-width: 50px;
      border: 1px solid var(--color-grey-light);
      border-radius: 5px;
    }

    & > p {
      font-weight: bold;
    }
  }
}
