$maxSize: 700px;

.modal {
  &__overlay,
  &__layout {
    opacity: 0;
    z-index: -1;
  }

  &--open > &__overlay,
  &--open > &__layout {
    z-index: 10;
  }

  &--open > &__overlay {
    opacity: 0.4;
  }

  &--open > &__layout {
    opacity: 1;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: var(--color-black);
    top: 0;
    left: 0;
  }

  &__layout {
    position: fixed;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--color-white);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__content {
      margin-top: 10px;
    }

    &__close {
      color: var(--color-black);
    }

    &--horizontal {
      max-width: $maxSize;
      max-height: calc(#{$maxSize} / 2);
    }

    &--vertical {
      max-width: calc(#{$maxSize} / 1.5);
      max-height: $maxSize;
    }
  }
}
