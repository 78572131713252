$inputSize: 70px;
$inputHeight: calc($inputSize / 2);
$circleSize: calc($inputHeight - 10px);

.settings {
  &__item {
    display: flex;
    align-items: center;

    &__title {
      margin: 20px 0 0;
    }

    &__content {
      color: var(--color-black);
      opacity: 0.7;
      margin: 10px 0 0;
      padding-right: 20px;
    }
  }

  &__input-toggle {
    input[type=checkbox]{
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: $inputSize;
      height: $inputHeight;
      background: var(--color-black);
      display: block;
      border-radius: 100px;
      position: relative;
      transition: background ease 300ms;
    }

    label:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: $circleSize;
      height: $circleSize;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      background: var(--color-orange);
    }

    input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
// 90 -> 130 = + 40
    label:active:after {
      width: calc($circleSize + 10px);
    }
  }
}
