.action-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  color: var(--color-black);

  &--full {
    height: 100%;
    width: 100%;
  }

  &--dark {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}
